<template>
    <b-container class="pdf-container">
        <object data="/tarifario-ServiPro.pdf" type="application/pdf" width="100%" height="">
            <p><a href="/tarifario-ServiPro.pdf">Click para descargar tarifas</a></p>
        </object>
    </b-container>
</template>
<script>
import { BContainer, BRow, BCard } from 'bootstrap-vue';
export default {
    components: { BContainer, BRow, BCard }
}
</script>
<style scoped lang="scss" >    
.pdf-container {
    height: 70vh;
}
.pdf-container object {
    height: 100%;
}
</style>